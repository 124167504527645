import { useState } from "react";
import moment from "moment";
import { Card, CardContent, Chip, Grid, TableCell, TableRow, Typography } from "@mui/material";

const AppointmentTabItem = ({ ID, Status, AppDate, AppTime, duration, notes }) => {
  return (
    <TableRow hover key={ID}>
      {/* Status Column */}
      <TableCell>
        {Status === "Cancelled" && (
          <Typography
            className=" rounded-pill d-inline-block px-2 fw-semibold  py-1"
            variant="body2" color="text.secondary"
            sx={{ fontSize: '12px', color: '#D92D20', bgcolor: '#FEE4E2' }}
          >
            Cancelled
          </Typography>
        )}
        {Status === "Completed" && (
          <Typography
            className=" rounded-pill d-inline-block px-2 fw-semibold  py-1"
            variant="body2" color="text.secondary"
            sx={{ fontSize: '12px', color: '#079455', bgcolor: '#DCFAE6' }}
          >
            Completed
          </Typography>
        )}
        {Status === "Upcoming" && (
          <Typography
            className=" rounded-pill d-inline-block px-2 fw-semibold  py-1"
            variant="body2" color="text.secondary"
            sx={{ fontSize: '12px', color: '#654E89', bgcolor: '#ECE8F2' }}
          >
            Upcoming
          </Typography>
        )}
      </TableCell>

      {/* Date Column */}
      <TableCell>
        <Typography variant="body2">
          {AppDate}
        </Typography>
      </TableCell>

      {/* Time Column */}
      <TableCell>
        <Typography variant="body2">
          {AppTime}
        </Typography>
      </TableCell>

      {/* Duration Column */}
      <TableCell>
        <Typography variant="body2">
          {duration}
        </Typography>
      </TableCell>


    </TableRow>
  );
};

export default AppointmentTabItem;
