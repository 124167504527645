import { useState, useEffect, useRef, forwardRef } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import moment from "moment";

import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import FormControl from "@mui/material/FormControl";
import Input from '@mui/material/Input';

import { useAuth } from "../../../auth";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";



//TextField
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";

//Icons
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";

import CircularProgress from '@mui/material/CircularProgress';
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { Chat, ChatBubbleOutlineOutlined, ChatOutlined } from "@mui/icons-material";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);


const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const TicketDetailsTableRow = ({
  date,
  detail,
  serialNO,
  topic,
  status,
  ticketID,
  selectedTab,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const formattedDate = moment(date).format("D MMM YYYY");
  const [openReply, setOpenReply] = useState(false);

  const { currentUser, userType, logout } = useAuth();
  const [chatHistory, setChatHistory] = useState([])


  const [chatData, setChatData] = useState({
    chatID: 0,
    clientID: 0,
    clientName: "",
    clientProfilePicture: "",
    clientAppointmentId: 0,
    chatHistory: [],
  });


  const [showAttachmentPreview, setShowAttachmentPreview] = useState(false);
  const [sendChatValue, setSendChatValue] = useState("");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");



  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };
  const chatHistoryRef = useRef(null);


  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };


  //Get Ticket History


  const postTicketsHistoryData = async () => {
    const payload = {
      ticket_id: ticketID,
    };
    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/ticket-reply-list`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const { replies } = await postResponse?.data;

      setChatHistory(replies)
      console.log("data", replies);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const sendTicketReply = async (replyText) => {
    const payload = {
      ticket_id: ticketID,
      reply_text: replyText,
      reply_from: 'expert'
    };
    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/ticket-reply`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      if (postResponse?.code === 200) {
        setSnackbarMessage(postResponse?.data?.message);
        setOpenSnackbar(true);
      }

    } catch (error) {
      console.error("Error:", error);
    }
  }

  const scrollChatToBottom = () => {
    //console.log("chatHistoryRef", chatHistoryRef)
    if (chatHistoryRef.current) {
      const chatContainer = chatHistoryRef.current;
      const lastChatMessage = chatContainer.lastElementChild;

      if (lastChatMessage) {
        lastChatMessage.scrollIntoView({ behavior: "smooth" });
      }
    }

  };

  useEffect(() => {
    scrollChatToBottom();
  }, [chatHistory]);

  const handleCloseReply = () => {
    setOpenReply(false);
  };

  const handleReplyToChat = () => {
    setOpenReply(true);
    postTicketsHistoryData()
    setTimeout(() => {
      scrollChatToBottom()
    }, 100)

  }

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setShowAttachmentPreview(true);
  };

  const handleRemoveAttachment = () => {
    setSelectedFile(null);
    setShowAttachmentPreview(false);
  };




  const handleTextChange = (e) => {
    setSendChatValue(e.target.value);
  };

  const handleReply = () => {
    //console.log(sendChatValue)
    let lastTicketID, lastSequence, currentDateTime, ticketID;
    if (chatHistory?.length > 0) {

      lastTicketID = chatHistory[chatHistory?.length - 1].id



      setChatHistory((prevData) => ([
        ...prevData,
        {
          id: +lastTicketID + 1,
          ticket_id: +ticketID,
          reply_text: sendChatValue,
          reply_from: "expert",
          sequence: lastSequence,
          reply_time: new Date().toISOString(),
          expert_reply: true
        }
      ]))

    } else {
      lastTicketID = 1;
      lastSequence = 1;



      setChatHistory([
        {
          id: +lastTicketID + 1,
          ticket_id: +ticketID,
          reply_text: sendChatValue,
          reply_from: "expert",
          sequence: lastSequence,
          reply_time: new Date().toISOString(),
          expert_reply: true
        }
      ])
    }


    setSendChatValue("")

    sendTicketReply(sendChatValue)
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && sendChatValue !== "") {
      // Call a function when Enter key is pressed
      handleReply()
    }
  };



  return (
    <>
      <TableRow
        className={`TicketDetails--TableRow `}
        sx={{
          backgroundColor: "#FFF",
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell className="Table--SRNO ">

          <p className="d-flex align-items-center gap-3">
            <p style={{
              height: "40px",

              width: "6px",
              background: status === "Resolved" ? "#47CD89" : "#F97066",

              borderRadius: "0px 10px 10px 0px"
            }}>
            </p>{serialNO}</p>
        </TableCell>
        <TableCell className="Table--Topic">
          <div>
            <p>{topic}</p>
            <span>{formattedDate}</span>
          </div>
        </TableCell>

        <TableCell className="Table--Detail">
          <p>{detail}</p>
        </TableCell>

        {/* <TableCell className="Table--Status">
          {status == "Resolved" ? (
            <p>Resolved</p>
          ) : (
            <p>Ongoing</p>
          )}
        </TableCell> */}

        <TableCell className="Table--Actions">
          {status == "Resolved" ? (
            <Typography
              className=" rounded-pill d-inline-block px-3 fw-semibold  py-1"
              variant="body2" color="text.secondary"
              sx={{
                fontSize: '14px',
                color: "#079455",
                bgcolor: "#DCFAE6"
              }}
            >
              Resolved
            </Typography>
          ) : (
            <Button
              variant="text"
              sx={{
                borderRadius: '20px', // Rounded but not too much
                bgcolor: '#f5f5f5',
                color: '#414141',
                fontSize: '14px', // 14px as in the default theme
                textTransform: 'capitalize',
                '&:hover': {
                  background: "#f5f5f5"
                }
              }} className=" rounded-3 px-3" startIcon={<ChatBubbleOutlineOutlined />}
              onClick={() => handleReplyToChat()}>

              Reply
            </Button>

          )}
        </TableCell>
      </TableRow>


      {/* Reply to ticket */}
      <Dialog
        className="ReplyTicket--Dialog"
        open={openReply}
        onClose={handleCloseReply}
      >

        <DialogContent>
          <div className="d-flex align-items-center border-bottom border-2 pe-2 justify-content-between">
            <div className="HelpChat--Header">
              <p>{topic}</p>
              <span>{formattedDate}</span>
            </div>
            <IconButton onClick={handleCloseReply}>
              <HighlightOffOutlinedIcon />
            </IconButton>
          </div>

          <div className="HelpChat--Chats" ref={chatHistoryRef}>
            {chatHistory
              ?.sort((a, b) => a.sequence - b.sequence)
              .map((chat, index) => {
                const isCurrentUser = chat.expert_reply === true;

                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: isCurrentUser ? "flex-end" : "flex-start",
                      mb: 3,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 2,
                        flexDirection: isCurrentUser ? "row-reverse" : "row",
                        maxWidth: "80%",
                      }}
                    >
                      {/* Avatar */}
                      <Avatar
                        src={
                          isCurrentUser
                            ? currentUser?.ProfileImage?.replace("https://app.uwc.worldhttps://", "https://")
                            : "/assets/img/favicon/asksamlogo.png"
                        }
                        alt={isCurrentUser ? currentUser?.Name : "Support Team"}
                        sx={{ width: 46, height: 46, objectFit: "cover" }}
                      />

                

                      {/* Message Area */}
                      <Box
                        sx={{
                          backgroundColor: isCurrentUser ? "#f0f4ff" : "#fff",
                          borderRadius: "12px",
                          p: 2,
                          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        }}
                      >
                        {/* Header: Name & Time */}
                        <Grid container direction="column">
                          <Typography
                            variant="subtitle2"
                            sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                          >
                            {isCurrentUser ? currentUser?.Name : "Support Team"}
                          </Typography>

                          <Typography
                            variant="body2"
                            sx={{ color: "gray", fontSize: "12px" }}
                          >

                            {dayjs.utc(chat?.reply_time).local().format("DD MMM YYYY hh:mm A")}
                          </Typography>
                        </Grid>

                        {/* Message Text */}
                        <Box mt={1}>
                          <Typography variant="body2" sx={{ mb: 1 }}>
                            {chat.reply_text}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
          </div>


          <div className="HelpChat--Send">
            <FormControl
              sx={{ width: "100%" }}
              className="rounded-3 TextBox--Container"
              variant="outlined"
            >
              {selectedFile && (
                <div className="SelectedFile">
                  <AttachFileIcon
                    sx={{ fill: "var(--primary-base-color)", transform: "rotate(45deg)" }}
                  />
                  <div>
                    <p>{selectedFile.name}</p>
                    <span>{`${(selectedFile.size / 1024).toFixed(
                      2
                    )} KB`}</span>
                  </div>

                  <IconButton
                    onClick={handleRemoveAttachment}
                    aria-label="remove"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              )}

              <div className="Input--Area">
                <Input
                  className="TextBox"
                  id="outlined-adornment-weight"
                  sx={{ background: "#FFF", borderRadius: "10px" }}
                  value={sendChatValue}
                  onChange={handleTextChange}
                  onKeyDown={handleKeyPress}
                  aria-describedby="outlined-weight-helper-text"
                  placeholder="Type you message here..."
                />
                <Button onClick={handleReply}>
                  Send
                </Button>
              </div>

            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>

        </DialogActions>
      </Dialog>


      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </>
  );
};

export default TicketDetailsTableRow;
