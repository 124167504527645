import { ArrowBack, Visibility } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Pagination,
  PaginationItem,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
// import { NoDataSvg } from "../../../clinicalNotesClinicalRoute/ClinicalNoteSVGs";
import { NoDataSvg } from "../../../helpers/Svg";
// import ScreenerDetailsView from "../ScreenerDetailsView";
import { set } from "date-fns";
// import ClientScreenerDetailsTable from "../../../tables/ClientScreenerDetailsTable";
import ClientGeneralQuestionsTable from "../../../tables/ClientGeneralQuestionsTable";

function WorkSheets({ clientDetails }) {
  const [loading, setLoading] = useState(true);
  const [screenerDetalis, setScreenerDetalis] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [assessments, setAssessments] = useState([]);

  //Fetch Client Screeners
  useEffect(() => {
    if (!clientDetails) {
      // If checkClientID is blank, return early
      return;
    }

    const postClientScreenersData = async () => {
      try {
        const screenerBody = {
          user_id: clientDetails,
          //  clientDetails
          page: page,
          limit: 10,
        };
        setLoading(true);

        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/expert/copilot/worksheets`,
          {
            method: "POST",
            headers: {
              "X-API-Key": `${process.env.REACT_APP_EXPERT_API_KEY}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(screenerBody),
          }
        );
        const postResponse = await response.json();

        const { worksheets } = await postResponse?.data;

        setAssessments(worksheets);
        setTotalPages(postResponse?.data?.total_pages);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientScreenersData();
  }, [clientDetails, page]);

  const handleViewClick = (questions, id, name) => {
    setScreenerDetalis({
      questions,
      id,
      name,
    });
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <div>
      {screenerDetalis?.id && (
        <IconButton
          sx={{
            backgroundColor: "var(--primary-color)",
            color: "white",
            "&:hover": {
              backgroundColor: "var(--primary-color)",
              color: "white",
            },
            height: "45px",
            width: "45px",
          }}
          onClick={() => setScreenerDetalis({})}
        >
          <ArrowBack />
        </IconButton>
      )}
      <div className="Tab--Panel Screeners--Panel">
        {!screenerDetalis?.id && loading ? (
          Array.from(new Array(10)).map((_, index) => (
            <div
              style={{
                width: "100%",
                height: "70px",
                backgroundColor: "rgb(0,0,0,0.07)",
                padding: "10px",
                borderRadius: "10px",
              }}
              className="d-flex justify-content-between"
            >
              <div className="d-flex flex-column gap-2">
                <Skeleton
                  variant="rounded"
                  height={18}
                  width="20vw"
                  sx={{ borderRadius: "10px" }}
                />
                <Skeleton
                  variant="rounded"
                  height={18}
                  width="15vw"
                  sx={{ borderRadius: "10px" }}
                />
              </div>

              <div className="CTA--Container d-flex gap-2 align-items-center">
                <Skeleton
                  variant="rounded"
                  height={38}
                  width={86}
                  sx={{ borderRadius: "20px" }}
                />
              </div>
            </div>
          ))
        ) : !screenerDetalis?.id && assessments?.length > 0 ? (
          <>
            {" "}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Submitted On</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {assessments?.map((item) => (
                  <TableRow className="">
                    {/* Screener Title */}
                    <TableCell>
                      <Typography variant="body2">
                        {" "}
                        {item?.name.charAt(0).toUpperCase() +
                          item?.name.slice(1).replace(/_/g, " ")}
                      </Typography>
                    </TableCell>

                    {/* Submitted On */}
                    <TableCell>
                      <Typography variant="body2">
                        {dayjs(item?.create_date).format("DD MMM YYYY")}
                      </Typography>
                    </TableCell>

                    {/* Action Buttons (View & Print) */}
                    <TableCell>
                      <Button
                        variant="text"
                        sx={{
                          borderRadius: "20px", // Rounded but not too much
                          bgcolor: "#f5f5f5",
                          color: "#414141",
                          fontSize: "14px", // 14px as in the default theme
                          textTransform: "capitalize",
                          "&:hover": {
                            background: "#f5f5f5",
                          },
                        }}
                        className="d-flex justify-content-center align-items-center gap-2 rounded-3 px-3 "
                        onClick={() =>
                          handleViewClick(item?.questions, item?.id, item?.name)
                        }
                      >
                        <Visibility />
                        View Result
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className="d-flex justify-content-end mb-5 p-4">
              <Box
                sx={{
                  "& .MuiPaginationItem-root": {
                    "&.Mui-selected": {
                      backgroundColor: "var(--primary-color)", // Selected page background color
                      color: "#fff", // Selected page text color
                    },
                    "&:hover": {
                      backgroundColor: "var(--primary-color)", // Hover background color
                    },
                  },
                }}
              >
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handleChange}
                  renderItem={(item) => <PaginationItem {...item} />}
                />
              </Box>
            </div>
          </>
        ) : (
          !screenerDetalis?.questions && (
            <div className="NoDataAvailable">
              {NoDataSvg}
              <p> No Worksheets Available</p>
            </div>
          )
        )}

        {screenerDetalis?.questions && (
          <div>
            <ClientGeneralQuestionsTable
              questions={screenerDetalis?.questions}
              name={screenerDetalis?.name}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default WorkSheets;
