import React, {useEffect} from 'react'
import ComingSoon from './component/ComingSoon'
//Helpers
import isUserLoggedIn from "./helpers/redirect";
import { useNavigate, useLocation } from 'react-router-dom';

const ExpertPostsDB = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  // Check if user is logged in
  // useEffect(() => {
   
  //   const isLoggedIn = isUserLoggedIn();

  //   if (!isLoggedIn) {
  //     // Redirect to "/login"
  //     //navigate('/login');
  //     window.location.href = "https://www.unitedwecare.com/";
  //   }
  // }, [location, navigate]);
  return (
    // <div className='my-3 ExpertPostsDashboard'>
    //     <div className='ExpertPostsDashboard--Header'>
    //         <h2>Posts</h2>
    //     </div>
    // </div>

    <ComingSoon />
  )
}

export default ExpertPostsDB