import { useState, useEffect } from "react";
import NotificationRowCard from "./cards/NotificationRowCard";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";
import Pagination from "@mui/material/Pagination";

import isUserLoggedIn from "./helpers/redirect";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "../../auth";
import { NoDataSvg } from "./helpers/Svg";

//Helper function to sort notifications by date
const sortNotificationsByDate = (notifications) => {
  const sortedNotifications = notifications.slice();

  sortedNotifications.sort((a, b) => {
    const dateA = moment(a.date_time.split(" ")[0]);
    const dateB = moment(b.date_time.split(" ")[0]);

    return dateB - dateA;
  });

  return sortedNotifications;
};

const groupNotificationsByDate = (notifications) => {
  const groupedNotifications = {};

  for (const notification of notifications) {
    const dateTimeParts = notification.date_time.split(" ");
    const date = dateTimeParts[0];
    if (!groupedNotifications[date]) {
      groupedNotifications[date] = [];
      groupedNotifications[date].push(
        moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")
      );
    }
    groupedNotifications[date].push(notification);
  }
  return groupedNotifications;
};

const ExpertNotificationsDB = ({ mainPage }) => {
  const [notifications, setNotifications] = useState([]);
  const [selectedTab, setSelectedTab] = useState("All");
  const [groupedNotifications, setGroupedNotifications] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Change itemsPerPage to control how many notifications per page

  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser } = useAuth();

  useEffect(() => {

    if (mainPage) {

      setSelectedTab("Appointments");
    } else {
      setSelectedTab("All");
    }

  }, [mainPage])


  // useEffect(() => {
  //   const isLoggedIn = isUserLoggedIn();
  //   if (!isLoggedIn) {
  //     window.location.href = `https://www${process.env.REACT_APP_CLIENT_DOMAIN}/`
  //   }
  // }, [location, navigate]);

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  // Helper function for delay
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getCurrentUserWithRetry = async (retries = 3, retryDelay = 3000) => {
    for (let attempt = 1; attempt <= retries; attempt++) {
      const storedUser = localStorage.getItem("profile");
      const currentUser = storedUser ? JSON.parse(storedUser) : null;

      if (currentUser && currentUser.UserId) {
        console.log(`User ID found on attempt ${attempt}`);
        return currentUser;
      } else {
        console.warn(`User ID not found on attempt ${attempt}. Retrying...`);
        if (attempt < retries) {
          await delay(retryDelay);
        }
      }
    }
    console.error("Failed to find currentUser after multiple attempts.");
    return null;
  };




  useEffect(() => {


    const postNotificationsData = async () => {
      try {
        const currentUser = await getCurrentUserWithRetry();
        if (!currentUser) {
          setIsLoading(false);
          return;
        }

        const payload = {
          user_id: currentUser?.UserId,
        };
        setIsLoading(true);
        const response = await fetch(
          `https://us.uwc.world/new-uwc/expert/dashboard/notifications`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();

        const data = await postResponse.data?.notifications;
        const sortedData = await sortNotificationsByDate(data);
        const groupedData = groupNotificationsByDate(sortedData);

        setNotifications(data);
        setGroupedNotifications(groupedData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setIsLoading(false);
      }
    };

    postNotificationsData();
  }, []);

  const handleTabSelect = (e) => {
    setSelectedTab(e.target.innerText);
    setCurrentPage(1); // Reset to first page when tab changes
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Paginate grouped notifications
  const filteredNotifications = Object.values(groupedNotifications)
    .map((item) => item.slice(1)) // Remove date (first item)
    .flat()
    .filter((el) => {
      if (selectedTab === "All") {
        return true;
      } else if (selectedTab === "Review") {
        return el.type === "review";
      } else if (selectedTab === "Appointments") {
        return el.type === "appointment";
      }
      return false;
    });

  const paginatedNotifications = filteredNotifications.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="my-3 border rounded-4 ExpertNotificationsDashboard">
      <div className="ExpertNotificationsDashboard--Header p-3">
        <h2>Notifications</h2>

        <div style={{ background: "#f0f0f0", maxWidth: mainPage ? "215px" : "271px" }} className="d-flex rounded-pill p-1 gap-2 Header--Options">
          {!mainPage && <div
            className={`Option--Item rounded-pill py-2 px-3 ${selectedTab === "All" ? "SelectedItem" : ""}`}
            onClick={(e) => handleTabSelect(e)}
          >
            All
          </div>}
          <div
            className={`Option--Item rounded-pill py-2 px-3 ${selectedTab === "Appointments" ? "SelectedItem" : ""}`}
            onClick={(e) => handleTabSelect(e)}
          >
            Appointments
          </div>
          <div
            className={`Option--Item rounded-pill py-2 px-3 ${selectedTab === "Review" ? "SelectedItem" : ""}`}
            onClick={(e) => handleTabSelect(e)}
          >
            Review
          </div>
        </div>
      </div>

      <div className="ExpertNotificationsDashboard--Content">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              margin: "10px 0px 5px 10px",
              paddingTop: "10px",
            }}
          >
            {Array.from(new Array(10)).map((_, index) => (
              <div key={index} style={{ display: "flex", alignItems: "center" }}>
                <Skeleton variant="circular" width={40} height={40} />
                <div
                  style={{
                    marginLeft: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <Skeleton variant="text" sx={{ fontSize: "1rem", width: "52%" }} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <Skeleton variant="text" sx={{ fontSize: "1rem", width: "25%" }} />
                    <Skeleton variant="text" sx={{ fontSize: "1rem", width: "25%" }} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : notifications === undefined ||
          notifications === null ||
          notifications?.length === 0 ? (
          <div className="NoDataAvailable">
            {NoDataSvg}
            <p>No Notifications Found</p>
          </div>
        ) : (
          <>
            <div>
              {paginatedNotifications.map((el) => (
                <NotificationRowCard
                  key={el.id}
                  image={el.image}
                  title={el.title}
                  clientID={el.uwc_user_id}
                  appointmentID={el.appointment_id}
                  type={el.type}
                  description={el.description}
                  day={el.day}
                  time={el.time}
                  clientType={el.client_type}
                  session={el.session}
                  joinNow={el.join_btn}
                />
              ))}
            </div>
            {/* Pagination Component */}
            {!mainPage && <div className="p-4" style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
              <Pagination
                count={Math.ceil(filteredNotifications.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}

              />
            </div>}
          </>
        )}
      </div>
    </div>
  );
};

export default ExpertNotificationsDB;
